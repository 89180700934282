/* Navbar */

.navbar-toggler, .navbar-toggler:focus {
  border: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: $menu-icon;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.nav-link {
  padding: 10px;
  padding-left: 10px !important;
  padding-right: 10px !important;
  border-radius: 100px;
}
.nav-link:hover {
  background-color: $dark-yellow;
}

.offcanvas {
  background-color: $light;
}

.dropdown-menu {
  background-color: $light;
  border: none;
}

.dropdown-item:hover, .dropdown-item:focus, .dropdown-item:active {
  background-color: $dark-yellow;
  color: $black;
}

/* Text */

h1, h2, h3,
h4, h5, h6 {
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}

.text-purple {
  color: $dark-purple;
}

.text-red {
  color: $dark-red;
}

.text-yellow {
  color: $dark-yellow;
}

.text-green {
  color: $green;
}
